import {
  Box,
  useMediaQuery,
  useTheme,
  useScrollTrigger,
  Fade,
} from "@mui/material";
import { lazy, memo, useState } from "react";

import { MainLayout } from "../../../components/Layout";
import MosaicSection from "../components/MosaicSection";
import { HEADER_TEXT } from "../../../constants";
import AffilliateModal from "../../../components/Modal/AffilliateModal";

const Header = lazy(() => import("../../../components/Header/Header"));
const TextSection = lazy(
  () => import("../../landing/components/TextSection/TextSection")
);
const CTATextBanner = lazy(
  () => import("../../../components/CTATextBanner/CTATextBanner")
);
const CTATextBannerMini = lazy(
  () => import("../../../components/CTATextBannerMini/CTATextBannerMini")
);
const FaqSection = lazy(() => import("../components/Faq"));
const OriginalProductions = lazy(
  () => import("../components/OriginalProductions")
);
const ShowCarouselSection = lazy(
  () => import("../components/ShowCarouselSection")
);
const ShowCaseSection = lazy(
  () => import("../components/ShowCaseSection/ShowCaseSection")
);
const SocialSection = lazy(() => import("../components/SocialSection"));

const MemoizedHeader = memo(Header);
const MemoizedMosaicSection = memo(MosaicSection);
const MemoizedTextSection = memo(TextSection);
const MemoizedCTATextBanner = memo(CTATextBanner);
const MemoizedCTATextBannerMini = memo(CTATextBannerMini);
const MemoizedFaqSection = memo(FaqSection);
const MemoizedOriginalProductions = memo(OriginalProductions);
const MemoizedShowCarouselSection = memo(ShowCarouselSection);
const MemoizedShowCaseSection = memo(ShowCaseSection);
const MemoizedSocialSection = memo(SocialSection);

const Landing = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState(null);

  const video = "/videos/showcase/21x9/output.mpd";
  const videoMobile = "/videos/showcase/4x5/output.mpd";

  const videoSrc = matchDownMd ? videoMobile : video;

  const hasScrolled = useScrollTrigger({
    disableHysteresis: true,
    threshold: 70,
  });

  return (
    <MainLayout
      title={"Universal+ France | Séries et films en live et en streaming​"}
      description={
        "Profitez de séries, films et plus encore : un vaste catalogue de contenus en live et en streaming !"
      }
      route={"/"}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          background: "#000",
        }}
        id="main"
      >
        <MemoizedHeader
          setModalData={setModalData}
          setOpen={setOpen}
          title={HEADER_TEXT}
        />
        <MemoizedMosaicSection />
        <MemoizedTextSection />
        <MemoizedShowCarouselSection />
        <MemoizedOriginalProductions />
        <MemoizedShowCaseSection videoSrc={videoSrc} />
        <MemoizedSocialSection />
        <MemoizedFaqSection />

        {!matchDownMd && hasScrolled && (
          <Fade in={hasScrolled} timeout={500} style={{ width: "100%" }}>
            <Box sx={{ position: "relative", zIndex: 500, width: "100%" }}>
              <MemoizedCTATextBanner />
            </Box>
          </Fade>
        )}

        {matchDownMd && hasScrolled && (
          <Fade in={hasScrolled} timeout={500} style={{ width: "100%" }}>
            <Box sx={{ position: "relative", zIndex: 500, width: "100%" }}>
              <MemoizedCTATextBannerMini />
            </Box>
          </Fade>
        )}
        <AffilliateModal modalData={modalData} setOpen={setOpen} open={open} />
      </Box>
    </MainLayout>
  );
};

export default Landing;
