import { useEffect, useRef, useState } from "react";
import { MediaPlayer } from "dashjs";
import {
  Box,
  CircularProgress,
  IconButton,
  useMediaQuery,
} from "@mui/material";
import { useInView } from "react-intersection-observer";
import { useTheme } from "@mui/material/styles";
import Fade from "@mui/material/Fade";
import CloseIcon from "@mui/icons-material/Close";

import CTAButtonSection from "../../../../../components/CTAButtonSection/CTAButtonSection";
import CTAButtonBannerMini from "../../../../../components/CTAButtonSectionMini/CTAButtonSectionMini";
import {
  MOSAIC_VIDEO_BUTTON,
  TOP_NAV_HEIGHT,
  TOP_NAV_MOBILE_HEIGHT,
} from "../../../../../constants";

import VideoControls from "./Videoontrols/VideoControls";

const videoMobile = "/videos/hero/4x5/output.mpd";

interface VideoBannerProps {
  showVideo: boolean;
  bannerHeight: string;
  setShowVideo: (arg: boolean) => void;
}

function VideoBannerMobile({
  showVideo,
  setShowVideo,
  bannerHeight,
}: VideoBannerProps) {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isMuted, setIsMuted] = useState(false);
  const [isPlaying, setIsPlaying] = useState(true);
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [vh, setVh] = useState(0);
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.1,
  });

  const initializeDashPlayer = () => {
    const videoElement = videoRef.current;
    if (!videoElement) {
      console.error("Video element not found");
      return;
    }

    const player = MediaPlayer().create();
    player.initialize(videoElement, videoMobile, true);

    player.on(MediaPlayer.events.PLAYBACK_ENDED, () => {
      setShowVideo(false);
    });

    player.on(MediaPlayer.events.ERROR, (e) => {
      console.error("Dash.js error", e);
    });

    return player;
  };

  // Updated useEffect to initialize Dash.js player on desktop and mobile
  useEffect(() => {
    if (videoRef.current) {
      const player = initializeDashPlayer();
      return () => player?.reset();
    }
  }, [videoMobile]);

  // Handle play and pause
  const handlePlayPause = () => {
    if (!videoRef.current) return;
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  // Toggle fullscreen mode
  const toggleFullScreen = () => {
    const video = videoRef.current;
    if (!video) return;
    if (!document.fullscreenElement) {
      video.requestFullscreen();
    } else {
      document.exitFullscreen();
    }
  };

  useEffect(() => {
    if (showVideo && videoRef.current) {
      videoRef.current.play();
    }
  }, [showVideo]);

  return (
    <Box
      sx={{
        height: bannerHeight,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        position: "relative",
        backgroundColor: "#000",
      }}
    >
      <Box
        ref={ref}
        sx={{
          height: "100%",
          position: "relative",
        }}
      >
        <Fade
          in={showVideo}
          timeout={100}
          style={{
            transitionTimingFunction: "cubic-bezier(0.645, 0.045, 0.355, 1)",
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: "100%",
              position: "relative",
              zIndex: 1,
            }}
          >
            <video
              ref={videoRef}
              style={{
                position: "relative",
                zIndex: 900,
                width: "100%",
                height: "100%",
                objectFit: "cover",
                visibility: isVideoLoaded ? "visible" : "hidden",
                opacity: isVideoLoaded ? 1 : 0,
              }}
              playsInline
              preload="auto"
              muted={isMuted}
              onLoadedData={() => setIsVideoLoaded(true)}
              onEnded={() => setShowVideo(false)}
            >
              <source src={videoMobile} type="application/dash+xml" />
            </video>
          </Box>
        </Fade>

        <Box
          sx={{
            position: "absolute",
            bottom: "32px",
            right: matchDownMd ? "16px" : "32px",
            zIndex: 50,
          }}
        >
          <VideoControls
            id={MOSAIC_VIDEO_BUTTON}
            isMuted={isMuted}
            isPlaying={isPlaying}
            setIsMuted={setIsMuted}
            setIsPlaying={setIsPlaying}
            toggleIsPlaying={handlePlayPause}
            toggleFullScreen={toggleFullScreen}
            handleRestartClick={() => {
              if (videoRef.current) videoRef.current.currentTime = 0;
              setIsPlaying(true);
              videoRef.current?.play();
            }}
          />
        </Box>

        <Box
          sx={{
            position: "absolute",
            top: "32px",
            right: matchDownMd ? "16px" : "32px",
            zIndex: 100,
            width: "100vw",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <IconButton
            sx={{
              p: "8px",
              bgcolor: "#ffffff90",
              border: "1px solid #ffffff90",
              "&:hover": {
                bgcolor: "#00000070",
                border: "1px solid #fff",
                "& svg": {
                  color: "#fff",
                },
              },
            }}
            onClick={() => setShowVideo(false)}
            type="button"
            aria-label={"Close video"}
          >
            <CloseIcon
              id={`${MOSAIC_VIDEO_BUTTON}-close`}
              sx={{
                color: "#00000090",
                fontSize: "16px",
              }}
            />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
}

export default VideoBannerMobile;
