import { lazy, memo, Suspense, useEffect, useState } from "react";
import { Box, CircularProgress, useMediaQuery, useTheme } from "@mui/material";

import heroImage from "../../../../assets/images/hero/21x9.webp";
import heroMobileImage from "../../../../assets/images/hero/4x5.webp";
import {
  MOSAIC_SECTION_ID,
  TOP_NAV_HEIGHT,
  TOP_NAV_MOBILE_HEIGHT,
} from "../../../../constants";

import HeroBanner from "./HeroBanner/HeroBanner";
import VideoBanner from "./VideoBanner/VideoBanner";
import VideoBannerMobile from "./VideoBanner/VideoBannerMobile";
import HeroBannerMobile from "./HeroBanner/HeroBannerMobile";

const MosaicSection = () => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));
  const [showVideo, setShowVideo] = useState(false);
  const [vh, setVh] = useState(0);

  useEffect(() => {
    const viewportHeight = window.innerHeight;
    setVh(viewportHeight);
  });

  const bannerHeight = `calc(${vh}px)`;

  const mobileBannerHeight = "600px";

  return (
    <Box
      component={"section"}
      id={MOSAIC_SECTION_ID}
      sx={{
        paddingTop: matchDownMd ? TOP_NAV_MOBILE_HEIGHT : TOP_NAV_HEIGHT,

        width: "100%",
        position: "relative",
        bgcolor: "#000",
      }}
    >
      {!showVideo && !matchDownMd && (
        <HeroBanner
          setShowVideo={setShowVideo}
          showVideo={showVideo}
          imgSrc={heroImage}
        />
      )}

      {showVideo && !matchDownMd && (
        <VideoBanner
          showVideo={showVideo}
          setShowVideo={setShowVideo}
          bannerHeight={bannerHeight}
        />
      )}

      {matchDownMd && (
        <Box
          sx={{
            height: mobileBannerHeight,
            width: "100%",
            display: "flex",
            flexDirection: "column",
            position: "relative",
            backgroundColor: "#000",
          }}
        >
          {!showVideo && matchDownMd && (
            <HeroBannerMobile
              setShowVideo={setShowVideo}
              showVideo={showVideo}
              imgSrc={heroMobileImage}
              bannerHeight={mobileBannerHeight}
            />
          )}

          {showVideo && matchDownMd && (
            <VideoBannerMobile
              showVideo={showVideo}
              setShowVideo={setShowVideo}
              bannerHeight={mobileBannerHeight}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default MosaicSection;
