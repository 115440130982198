import { Link } from "@mui/material";
import * as React from "react";

type LogoProps = {
  height?: string;
  imgSrc: string;
  sx?: React.CSSProperties;
  href?: string;
  link?: boolean;
  onClick?: () => void;
};

export const Logo = ({
  height,
  imgSrc,
  sx = {},
  href,
  link = false,
  onClick,
}: LogoProps) => {
  if (!link) {
    return (
      <img
        onClick={onClick}
        src={imgSrc}
        alt="Logo"
        style={{ height: `${height}px`, ...sx }}
      />
    );
  }
  return (
    <Link
      target="_blank"
      rel="noreferrer"
      className="flex items-center"
      href={href}
    >
      <img
        src={imgSrc}
        alt="Universal Plus Logo"
        className={""}
        style={{ height: `${height}px`, ...sx }}
      />
    </Link>
  );
};
