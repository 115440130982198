import { Box, IconButton } from "@mui/material";
import PauseCircleOutlineOutlinedIcon from "@mui/icons-material/PauseCircleOutlineOutlined";
import PlayCircleFilledWhiteOutlinedIcon from "@mui/icons-material/PlayCircleFilledWhiteOutlined";
import VolumeMuteOutlinedIcon from "@mui/icons-material/VolumeMuteOutlined";
import VolumeUpOutlinedIcon from "@mui/icons-material/VolumeUpOutlined";
import FullscreenOutlinedIcon from "@mui/icons-material/FullscreenOutlined";
import ReplayIcon from "@mui/icons-material/Replay";

interface VideoControlsProps {
  id: string;
  isMuted: boolean;
  isPlaying: boolean;
  setIsMuted: (value: boolean) => void;
  setIsPlaying: (value: boolean) => void;
  toggleFullScreen: () => void;
  toggleIsPlaying: () => void;
  handleRestartClick: () => void;
}

const VideoControls = ({
  id,
  isMuted,
  isPlaying,
  setIsMuted,
  setIsPlaying,
  toggleIsPlaying,
  toggleFullScreen,
  handleRestartClick,
}: VideoControlsProps) => {
  return (
    <Box sx={{ zIndex: 500 }}>
      <IconButton
        sx={{
          p: "0 4px",
        }}
        onClick={handleRestartClick}
        type="button"
        aria-label="Replay"
      >
        <ReplayIcon
          sx={{
            width: "32px",
            color: "#fff",
          }}
        />
      </IconButton>

      <IconButton
        sx={{ p: "0 4px" }}
        onClick={toggleIsPlaying}
        type="button"
        aria-label={isPlaying ? "Pause" : "Play"}
      >
        {isPlaying && (
          <PauseCircleOutlineOutlinedIcon
            id={`${id}-pause`}
            sx={{
              width: "32px",
              color: "#fff",
              "& path": {
                pointerEvents: "none",
              },
            }}
          />
        )}
        {!isPlaying && (
          <PlayCircleFilledWhiteOutlinedIcon
            id={`${id}-play`}
            sx={{
              width: "32px",
              color: "#fff",
              "& path": {
                pointerEvents: "none",
              },
            }}
          />
        )}
      </IconButton>

      <IconButton
        id={id}
        sx={{ p: "0 4px" }}
        onClick={() => setIsMuted(!isMuted)}
        type="button"
        aria-label={isMuted ? "Play sound" : "Turn off sound"}
      >
        {isMuted && (
          <VolumeMuteOutlinedIcon sx={{ width: "32px", color: "#fff" }} />
        )}
        {!isMuted && (
          <VolumeUpOutlinedIcon sx={{ width: "32px", color: "#fff" }} />
        )}
      </IconButton>

      <IconButton
        sx={{ p: "0 4px" }}
        onClick={toggleFullScreen}
        type="button"
        aria-label={"Toggle fullscreen"}
      >
        <FullscreenOutlinedIcon sx={{ width: "32px", color: "#fff" }} />
      </IconButton>
    </Box>
  );
};

export default VideoControls;
