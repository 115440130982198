import { useEffect } from "react";
import { Box, CardMedia, Fade, useMediaQuery, useTheme } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

import { Logo } from "../../../../../components/Logo/Logo";
import LogoImage from "../../../../../assets/images/logos/universal-plus.png";

interface HeroBannerProps {
  showVideo: boolean;
  imgSrc: string;
  bannerHeight: string;
  setShowVideo: (showVideo: boolean) => void;
}

const HeroBannerMobile = ({
  showVideo,
  imgSrc,
  setShowVideo,
  bannerHeight,
}: HeroBannerProps) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      sx={{
        display: "flex",
        position: "relative",
        flexDirection: "column",
        height: "100%",
        width: "100vw",
      }}
    >
      <Box
        sx={{
          position: "relative",
          height: "100%",
        }}
      >
        <PlayCircleIcon
          onClick={() => setShowVideo(true)}
          sx={{
            fontSize: "80px",
            position: "absolute",
            color: "#ffffff50",
            top: "75%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            cursor: "pointer",
          }}
        />
        <CardMedia
          component="img"
          alt="Main mobile hero image"
          src={imgSrc}
          sx={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
          }}
        />
        <Box
          sx={{
            position: "absolute",
            zIndex: 900,
            top: "40%",
            left: "30px",
          }}
        >
          <Logo imgSrc={LogoImage} height={"120"} />
        </Box>
      </Box>
    </Box>
  );
};

export default HeroBannerMobile;
