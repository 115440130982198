import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Link, useMediaQuery, useTheme } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

import { Logo } from "../Logo/Logo";
import { ModalData } from "../../types/modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "#000",
  boxShadow: 24,
  borderRadius: "8px",
  border: "1px solid #fff",
};

interface BasicModalProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  modalData: ModalData | null;
}

const AffilliateModal = ({ open, setOpen, modalData }: BasicModalProps) => {
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down("md"));

  const handleClose = () => setOpen(false);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{}}
    >
      <Box sx={style}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "end",
            p: "16px 16px 0px 0px",
          }}
        >
          <CancelIcon
            sx={{ color: "#fff", cursor: "pointer" }}
            fontSize={"large"}
            onClick={() => handleClose()}
          />
        </Box>

        <Box
          sx={{
            p: 4,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Logo
            imgSrc={modalData?.imgSrc!}
            height={modalData?.popupImgHeight}
            href={modalData?.link}
            link={true}
          />

          <Typography
            sx={{
              pt: "32px",
              whiteSpace: "pre-line",
              fontSize: matchDownMd ? "12px" : "14px",
              color: "#fff",
              textAlign: "center",
            }}
          >
            {modalData?.text}
          </Typography>
        </Box>
      </Box>
    </Modal>
  );
};

export default AffilliateModal;
