import { Box, CardMedia, Fade, useTheme } from "@mui/material";
import PlayCircleIcon from "@mui/icons-material/PlayCircle";

import { Logo } from "../../../../../components/Logo/Logo";
import universalLogo from "../../../../../assets/images/logos/universal-plus.png";

interface HeroBannerProps {
  showVideo: boolean;
  imgSrc: string;
  setShowVideo: (showVideo: boolean) => void;
}

const HeroBanner = ({ showVideo, imgSrc, setShowVideo }: HeroBannerProps) => {
  return (
    <Box sx={{ psoition: "relative", height: "100%", width: "100%" }}>
      <Fade
        in={!showVideo}
        timeout={500}
        style={{
          transitionTimingFunction: "cubic-bezier(0.645, 0.045, 0.355, 1)",
          width: "100%",
          height: "100%",
        }}
      >
        <Box>
          <PlayCircleIcon
            onClick={() => setShowVideo(true)}
            sx={{
              fontSize: "80px",
              position: "absolute",
              color: "#ffffff50",
              top: "calc(50% + 70px)",
              left: "50%",
              transform: "translate(-50%, -50%)",
              cursor: "pointer",
            }}
          />
          <CardMedia
            component="img"
            alt="Main hero image"
            src={imgSrc}
            sx={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
            }}
          />
        </Box>
      </Fade>

      <Box
        sx={{
          position: "absolute",
          left: 50,
          top: "calc(50% - 40px)",
          zIndex: 600,
        }}
      >
        <Logo imgSrc={universalLogo} height={"240"} />
      </Box>
    </Box>
  );
};

export default HeroBanner;
